const genDate = (value?: string, defaultValue?: null|Date): null|Date => {
    if (value && value !== '') {
        return new Date(value)
    }

    if (defaultValue) {
        return defaultValue
    }

    return new Date()
}

const genDateStringList = (dates?: Array<string>): string[] => {
    if (dates && dates.length > 0) {
        let res = []
        dates.forEach(function (element: string) {
            let newDate = genDate(element, null);
            if (newDate !== null) {
                res.push(newDate.toDateString())
            }
        })
        return res
    }
    return null
}

type CalendarOptions = {
    disableWeekDays?: Array<number>,
    minDate?: null|string,
    maxDate?: null|string,
    disabledDates?: null|Array<string>,
    allowedDates?: null|Array<string>,
}

const isValidDate = (
    date: null|Date,
    options: CalendarOptions
) => {
    if (date === null || isNaN(date.getTime())) {
        return false
    }

    const allowedDates: string[] = genDateStringList(options.allowedDates)
    const disabledDates: string[] = genDateStringList(options.disabledDates)

    const isInAllowedDates = (date: Date): boolean => {
        if (allowedDates) {
            return allowedDates.includes(date.toDateString())
        }
        return false
    }

    const isInDisabledDates = (date: Date): boolean => {
        if (disabledDates) {
            return disabledDates.includes(date.toDateString())
        }
        return false
    }

    const isInDisableWeekDays = (date: Date): boolean => {
        if (options.disableWeekDays) {
            return options.disableWeekDays.includes(date.getDay())
        }
        return false
    }

    if (options.minDate !== null && date < new Date(options.minDate)) {
        return false
    }

    if (options.maxDate !== null && date > new Date(options.maxDate)) {
        return false
    }

    if (isInDisableWeekDays(date) || isInDisabledDates(date)) {
        return isInAllowedDates(date)
    }

    if (allowedDates && !disabledDates && !options.disableWeekDays) {
        return isInAllowedDates(date)
    }

    return true
}

export default {
    isValidDate,
}
