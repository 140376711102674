<script setup>

import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import calendarHelpers from '@/calendar-helpers'

import { computed, ref, watch } from 'vue'
import {debounce} from "lodash";

const props = defineProps({
    id: String,
    modelValue: String,
    minDate: String,
    maxDate: String,
    disabledDates: Array,
    allowedDates: Array,
    disabledWeekDays: Array,
    onChange: Function
})

const emits = defineEmits(['update:modelValue'])

const genDate = (value, defaultValue) => {
    if (value !== undefined && typeof value === 'string' && value !== '') {
        return new Date(value)
    }

    if (defaultValue !== undefined) {
        return defaultValue
    }

    return new Date()
}

const date = ref(genDate(props.modelValue, null))

const updateDate = (modelData) => {
    emits('update:modelValue', modelData?.toISOString() ?? '')
}

const textInputOptions = ref({
    enterSubmit: true,
    tabSubmit: true,
    openMenu: true,
    format: 'dd.MM.yyyy'
})

const dateFormat = (date) => {
    if (typeof date !== "object") {
        return ''
    }
    const day = ('0' + (date.getDate())).slice(-2)
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()

    return `${day}.${month}.${year}`
}

const filterDates = (date) => !calendarHelpers.isValidDate(date, {
    disableWeekDays: props.disabledWeekDays,
    minDate: props.minDate,
    maxDate: props.maxDate,
    disabledDates: props.disabledDates,
    allowedDates: props.allowedDates,
})

watch(() => props.modelValue, (value) => {
    if (value === null || value === '') {
        date.value = null
        props.onChange(null)
        return
    }
    date.value = genDate(value, null)
    props.onChange(date.value)
})

const dateMinDate = computed(() => {
    return genDate(props.minDate, null)
})

const dateMaxDate = computed(() => {
    return genDate(props.maxDate, null)
})

watch(
    () => [
      props.minDate,
      props.maxDate,
      props.disabledDates,
      props.allowedDates,
      props.disabledWeekDays,
    ],
    debounce(([
      minDate,
      maxDate,
      disabledDates,
      allowedDates,
      disabledWeekDays,
    ]) => {
      const d = date?.value?.toString() ?? ''
      if (d === '') {
        return
      }
      if (!calendarHelpers.isValidDate(new Date(d), {
        disableWeekDays: disabledWeekDays,
        minDate: minDate,
        maxDate: maxDate,
        disabledDates: disabledDates,
        allowedDates: allowedDates,
      })) {
        updateDate(null)
      }
    }, 10)
)
</script>
<template>
    <Datepicker v-model="date"
                @update:model-value="updateDate"
                :id="id"
                :enable-time-picker="false"
                :format="dateFormat"
                :text-input="textInputOptions"
                :min-date="dateMinDate"
                :max-date="dateMaxDate"
                :disabled-dates="filterDates"
                :start-date="genDate(maxDate, null) ?? genDate(minDate, null) ?? new Date()"
                :prevent-min-max-navigation="dateMinDate !== null || dateMaxDate !== null"
                auto-apply>
        <template #dp-input="{ value, onInput, onEnter, onTab, onClear }">
            <input type="text"
                   :id="id + '-value'"
                   :value="value"
                   @input="onInput"
                   @keydown.enter.prevent="onEnter"
                   @keydown.tab="onTab"
                   class="block w-full border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm"/>
        </template>
    </Datepicker>
</template>
