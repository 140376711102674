<script setup>
import { ref } from 'vue'
import IconPlus from '@/Components/Icons/IconPlus.vue'
import IconMiniXMark from '@/Components/Icons/IconMiniXMark.vue'
import FormInput from '@/Components/Form/FormInput.vue'
import { max } from 'lodash'

const props = defineProps({
    input: Object,
    form: Object,
    onGenerateInput: {
        type: Function,
        default: (input) => input
    },
})

const dataset = ref([])

const regenerateDatasetInputs = () => {

    const generateSubInput = (sampleInput, index) => {
        return props.onGenerateInput(
            {
                ...sampleInput,
                ...{
                    key: [props.input.key, index, sampleInput.key],
                    default: props.form[props.input.key]?.[index]?.[sampleInput.key] ?? props.defaults?.[sampleInput.key] ?? sampleInput.default ?? '',
                }
            }
        )
    }

    const newDataset = []
    for (let newIndex in dataset.value) {
        const inputs = []
        for (let input in props.input.inputs) {
            inputs.push(generateSubInput(props.input.inputs[input], newIndex))
        }
        newDataset.push(inputs)
    }
    dataset.value = newDataset
}

const addNewRowToDataset = (index) => {
    if (typeof index !== 'number' || dataset.value.length ===0) {
        index = dataset.value.length
    }

    if (dataset.value.length === 0 || index === dataset.value.length) {
        dataset.value.push([])
        regenerateDatasetInputs()
        return;
    }

    if (index === 0) {
        dataset.value.unshift([])
        regenerateDatasetInputs()
        return;
    }

    dataset.value.splice(index, 0, [])
    regenerateDatasetInputs()
}

const removeLatestRowFromDataset = () => {
    if (dataset.value.length <= props.input.min) {
        return;
    }
    dataset.value.pop()
}

const removeRemoveDataset = (index) => {
    if (dataset.value.length <= props.input.min) {
        return;
    }
    removeSubInputsValueFromForm(props.input.key, index)
    dataset.value.splice(index, 1)
}

const removeSubInputsValueFromForm = (parentKey, index) => {
  if (typeof props.form[parentKey] === 'undefined') {
    return;
  }

  if (typeof props.form[parentKey][index] === 'undefined') {
    return;
  }

  props.form[parentKey].splice(index, 1)
}

const defaultDatasetCount = max([props.input.min, props.form[props.input.key]?.length ?? 0])
for (let i = 0; i < defaultDatasetCount; i++) {
  addNewRowToDataset(i)
}

</script>

<template>
  <slot name="inputs" :inputs="input.inputs" :form="form" :dataset="dataset">
    <slot v-for="(datasetRow, index) in dataset" name="row" :index="index" :datasetRow="datasetRow" :form="form">
      <div class="flex items-center">
        <div class="mr-4">{{index + 1}}.</div>
        <div class="grow">
          <div class="grid xl:grid-cols-3 gap-4 mb-4">
              <slot v-for="subInput in datasetRow" name="input" :dataset="dataset" :index="index" :datasetRow="datasetRow" :input="subInput" :form="form">
                  <div>
                      <FormInput :input="subInput" :form="form"/>
                  </div>
              </slot>
          </div>
        </div>
        <div class="ml-4">
          <button type="button" :disabled="dataset.length <= 1" @click="removeRemoveDataset(index)"
                  :class="{ 'opacity-50 cursor-not-allowed': dataset.length <= props.input.min }"
                  class="border border-slate-500 hover:border-slate-700 transition-all text-slate-500 hover:text-slate-700 rounded-full text-xs">
            <IconMiniXMark/>
          </button>
        </div>
      </div>
    </slot>
  </slot>
  <slot name="addButton" :addNewRowToDataset="addNewRowToDataset">
    <div class="text-center py-3">
      <button type="button" @click="addNewRowToDataset"
              class="border border-blue-500 hover:border-blue-700 transition-all text-blue-500 hover:text-blue-700 rounded-full p-2">
        <IconPlus/>
      </button>
    </div>
  </slot>
</template>
