<script setup>
import InputLabel from "@/Components/Form/InputLabel.vue";
import InputError from "@/Components/Form/InputError.vue";
import TextInput from "@/Components/Form/TextInput.vue";
import TextareaInput from "@/Components/Form/TextareaInput.vue";
import Select from "@/Components/Form/Select.vue";
import { onMounted, onUnmounted, ref, watch } from 'vue'
import {debounce} from "lodash";
import Checkbox from "@/Components/Form/Checkbox.vue";
import DatepickerInput from "@/Components/Form/DatepickerInput.vue";
import InputArray from '@/Components/Form/InputArray.vue'

const typeInInputs = [
    'textarea',
    'text',
    'password',
    'email',
    'number',
    'url',
    'date',
    'datetime-local',
    'month',
    'week',
    'time',
    'search',
    'tel',
];

const typeValue = ['text', 'select', 'datepicker'];

const props = defineProps({
    input: Object,
    form: Object,
    focus: {
        type: Boolean,
        default: false,
    },
    modelValue: String,
    options: Array,
    minDate: String,
    maxDate: String,
    disabledDates: Array,
    allowedDates: Array,
    disabledWeekDays: Array,
    onChange: {
        type: Function,
        default: (value) => {
        }
    },
    prefix: String|null,
})

const emits = defineEmits(['update:modelValue']);

const inputId = (typeof props.input.key === 'object') ? props.input.key.join('.') : props.input.key


const generateFormElement = (key) => {
  if (typeof key === 'string') {
    if (typeof props.form[key] === 'undefined') {
      props.form[key] = props.modelValue ?? props.input.default ?? ''
    }
    return
  }

  if (typeof props.form[key[0]] === 'undefined') {
    props.form[key[0]] = []
  }

  if (typeof props.form[key[0]][key[1]] === 'undefined') {
    props.form[key[0]][key[1]] = {}
  }

  if (props.form[key[0]][key[1]].hasOwnProperty(key[2]) === false) {
    props.form[key[0]][key[1]][key[2]] = props.defaults?.[key] ?? props.input.default ?? ''
  }
}

const _value = ref(props.modelValue ?? props.input.default ?? '')
let valueWatchFns = []

const syncFormValue = (value) => {
    if (typeof props.input.key === 'string') {
        props.form[props.input.key] = value
    } else {
        generateFormElement(props.input.key)
        props.form[props.input.key[0]][props.input.key[1]][props.input.key[2]] = value
    }
}

valueWatchFns.push(syncFormValue)
if (typeInInputs.includes(props.input.type)) {
    valueWatchFns.push((val) => debounce(() => {
        props.onChange(val)
    }, 300))
}

watch(_value, (val) => {
    valueWatchFns.forEach((fn) => fn(val))
    emits('update:modelValue', val)
})
watch(props.form, (newForm, oldForm) => {
    if (typeof props.input.key === 'string') {
        if (newForm[props.input.key] === _value.value) {
            return
        }
        _value.value = props.modelValue ?? newForm[props.input.key]
        return
    }

    if (typeof newForm[props.input.key[0]] === 'undefined') {
        return
    }

    if (typeof newForm[props.input.key[0]][props.input.key[1]] === 'undefined') {
        return
    }

    if (newForm[props.input.key[0]][props.input.key[1]][props.input.key[2]] === _value.value) {
        return
    }

    _value.value = props.modelValue ?? newForm[props.input.key[0]][props.input.key[1]][props.input.key[2]]
})

onMounted(() => {
    generateFormElement(props.input.key)

    if (typeof props.input.key === 'string') {
        _value.value = props.modelValue ?? props.form[props.input.key]
        return
    }

    _value.value = props.modelValue ?? props.form[props.input.key[0]][props.input.key[1]][props.input.key[2]]

    syncFormValue(_value.value)
})

watch(() => props.modelValue, debounce((val) => {
    _value.value = val
}, 300))

onUnmounted(() => {
    if (typeof props.input.key === 'string') {
        delete props.form[props.input.key]
        return
    }

    if (typeof props.form[props.input.key[0]] === 'undefined') {
        return
    }

    if (typeof props.form[props.input.key[0]][props.input.key[1]] === 'undefined') {
        return
    }

    if (props.input.key.length === 2) {
        props.form[props.input.key[0]].splice(props.input.key[1], 1)
        return
    }

    if (props.form[props.input.key[0]][props.input.key[1]].hasOwnProperty(props.input.key[2]) === false) {
        return
    }

    delete props.form[props.input.key[0]][props.input.key[1]][props.input.key[2]]
    if (Object.keys(props.form[props.input.key[0]][props.input.key[1]]).length === 0) {
        props.form[props.input.key[0]].splice(props.input.key[1], 1)
    }
})

</script>
<template>
    <hr v-if="Object.keys(input).length === 0" class="border-gray-200 dark:border-gray-700"/>
    <InputArray v-else-if="input.type === 'array'" :input="input" :form="form"/>
    <template v-else>
        <template v-if="input.type === 'checkbox'">
            <Checkbox
                :id="inputId"
                :type="input.type"
                v-model="_value"
                :checked="input.default"
                :on-change="onChange"
                @change="onChange"/>
            <label :for="input.id" class="ml-2 text-sm text-gray-600">{{input.label}}</label>
        </template>
        <div v-else-if="['radio'].includes(input.type)" class="flex items-center">
            <input
                :id="inputId"
                :type="input.type"
                v-model="_value"/>
            <label :for="input.id" class="px-3">{{ input.label }}</label>
        </div>
        <InputLabel v-else-if="!['hidden'].includes(input.type)" :for="input.id" :value="input.label"/>
        <div class="flex justify-items-stretch">
            <div v-if="prefix" class="p-2 mt-1 bg-white border rounded-l-md shadow-sm flex items-center">{{ prefix }}</div>
            <div class="grow">
                <TextInput v-if="[
                    'text',
                    'password',
                    'email',
                    'number',
                    'url',
                    // 'date',
                    'datetime-local',
                    'month',
                    'week',
                    'time',
                    'search',
                    'tel',
                    ].includes(input.type)"
                           :id="inputId"
                           :type="input.type"
                           :placeholder="input.placeholder"
                           v-model="_value"
                           class="mt-1 block w-full"
                           :class="{
                               'rounded-r-md': !!prefix,
                               'rounded-md': !prefix,
                           }"
                           :required="input.required"
                           :autofocus="focus"
                />
                <DatepickerInput v-else-if="input.type === 'date'"
                                 :id="inputId"
                                 class="mt-1"
                                 :min-date="minDate ? minDate : input.min"
                                 :max-date="maxDate ? maxDate : input.max"
                                 :placeholder="input.placeholder"
                                 :allowed-dates="allowedDates ? allowedDates : (input?.allowedDates ?? [])"
                                 :disabled-dates="disabledDates ? disabledDates : (input?.disabledDates ?? [])"
                                 :disabled-week-days="disabledWeekDays ? disabledWeekDays : input.disabledWeekDays"
                                 :on-change="onChange"
                                 v-model="_value"/>
                <TextareaInput v-else-if="input.type === 'textarea'"
                               :id="inputId"
                               :type="input.type"
                               v-model="_value"
                               class="mt-1 block w-full"
                               :required="input.required"
                               :autofocus="focus"
                               @change="onChange(_value)"/>
                <Select v-else-if="input.type === 'select'"
                        :id="inputId"
                        :options="options ? options : input.options ?? []"
                        v-model="_value"
                        :autofocus="focus"
                        @change="onChange(_value)"/>
                <input v-else-if="input.type === 'hidden'"
                       :id="inputId"
                       :type="input.type"
                       v-model="_value"/>
            </div>
        </div>
        <InputError class="mt-2" :message="form.errors[inputId]"/>
    </template>
</template>
